<template>
  <div>
    <v-row justify="end" align="center">
      <v-col class="pa-0 text-center" cols="12" md="7" v-if="!gs.isMobile()">
        <img
          alt="Banker Register"
          src="@/assets/banker-register-bg.svg"
          style="
            padding: 48px;
            padding-top: 0px;
            object-fit: contain;
            width: inherit;
            max-height: 950px;
          "
        />
      </v-col>
      <v-col
        :class="`pa-0 ${gs.isMobile() ? '' : 'pr-12'}`"
        cols="12"
        md="5"
        style="
          display: flex;
          justify-content: start;
          align-items: center;
          align-self: flex-start;
        "
      >
        <div>
          <div>
            <v-row :class="`text-${gs.isMobile() ? 'center' : 'left'}`">
              <v-col
                :cols="gs.isMobile() ? '12' : '8'"
                :style="
                  gs.isMobile() ? 'padding-top: 2rem;' : 'padding-top: 10rem;'
                "
              >
                <h1
                  class="px-5"
                  style="
                    font-weight: bold;
                    color: var(--white);
                    font-size: 44px;
                    line-height: 42px;
                  "
                >
                  {{ $t(`banker_register_finish_1`) }}
                </h1>
              </v-col>
              <v-col :cols="gs.isMobile() ? '12' : '8'">
                <h5
                  class="px-5 my-3 mb-5"
                  style="
                    font-size: 18px;
                    line-height: 21px;
                    color: var(--white);
                  "
                  :style="gs.isMobile() ? 'width: 100%' : 'width: 90%'"
                >
                  {{ $t(`banker_register_finish_2`) }}
                </h5>
              </v-col>
              <v-col
                cols="12"
                :style="
                  gs.isMobile() ? 'padding-top: 5rem' : 'padding-top: 15rem'
                "
              >
                <v-btn
                  data-test="Banker:FinishPage:AcceptPlatform"
                  @click="access_plataform"
                  :loading="loading"
                  class="dxa-btn-primary-blue register-btn"
                >
                  {{ $t("access_plataform") }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "BankerRegisterFinishPage",
  data() {
    return {
      apiService: new ApiService(),
      gs: new GeneralServices(),
      loginInfo: null,
      loading: false,
      UserTypeEnum,
    };
  },

  async created() {
    this.loginInfo = this.$route.query.login;
  },

  methods: {
    async access_plataform() {
      if (!this.loginInfo || this.loginInfo === "null") {
        this.redirect_to_login();
      } else {
        this.loading = true;
        var user = { LoginEncrypted: this.loginInfo };
        await this.apiService
          .postRequest("user/token", user, false)
          .then((result) => {
            const user = JSON.stringify(result.user);
            this.$store.commit("SET_LOGGED_USER", user);
            this.$store.commit("SET_CURRENT_TOKEN", result.token);
            this.$store.commit("SET_EXPIRES_TOKEN", result.expires);

            if (result.user.type === UserTypeEnum.Banker) {
              if (result.user.hasRenewContractToSign === true) {
                localStorage.setItem("signContractLater", false);
                if (result.user.isOnboarded === true) {
                  this.$router.push("/renewContracts");
                }
              } else localStorage.setItem("signContractLater", true);
            }

            if (result.user.email) {
              window.$crisp.push(["set", "user:email", [result.user.email]]);
            }
            if (result.user.fullName) {
              window.$crisp.push([
                "set",
                "user:nickname",
                [result.user.fullName],
              ]);
            }
            if (result.user.image) {
              window.$crisp.push([
                "set",
                "user:avatar",
                [this.gs.get_photo_path(result.user.image)],
              ]);
            }
            this.$router.push("/");
          })
          .catch((err) => {
            this.$toast.error(this.$t(err.body.message));
          });
        this.loading = false;
      }
    },
    redirect_to_register() {
      this.$router.push(`/auth/banker-register/${this.$route.query.partner}`);
    },
    redirect_to_login() {
      this.$router.push(`/auth`);
    },
  },
};
</script>
<style>
.crisp-client {
  display: none;
}
</style>
<style scoped>
.register-btn {
  width: 320px;
  height: 50px !important;
  font-size: 22px !important;
  box-shadow: 0px 4px 20px #1a7dff !important;
}
</style>
